/**
 * Custom scripts
 * ==============
 */
// javascript is enabled
import _ from 'lodash';
import { Tab, Collapse, ScrollSpy } from 'bootstrap';
import { Howl, Howler } from 'howler';
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';
import animation from './gsap.js';
import { smoothScroll } from './smoothscroll';
import LazyLoad from 'vanilla-lazyload';

const body = document.getElementsByTagName('body')[0];
body.classList.remove('no-js');

let showMenu = (menuAction) => {
	const menu = document.getElementById('fullscreenMenu');

	if (menuAction == 'open') {
		body.classList.add('openedMenu');
		menu.setAttribute('aria-hidden', 'false');
	}
	if (menuAction == 'close') {
		body.classList.remove('openedMenu');
		menu.setAttribute('aria-hidden', 'true');
	}
};
window.addEventListener('load', () => {
	animation();
	let lazyLoadInstance = new LazyLoad();
});
document.addEventListener('DOMContentLoaded', () => {
	const menu = document.getElementById('menuBtn');
	const menuClose = document.getElementsByClassName('closeBtn')[0];

	const pageHome = document.getElementById('pageHome');
	if (pageHome) {
		/* accordion */

		let collapsibles = document.querySelectorAll('#accordionExample .accordion-collapse');
		collapsibles.forEach((element) => {
			element.addEventListener('show.bs.collapse', function () {
				this.parentElement.classList.add('opened');
			});
			element.addEventListener('hide.bs.collapse', function () {
				this.parentElement.classList.remove('opened');
			});
		});

		const bodyEL = document.getElementsByTagName('body')[0];
		
		

		/* scrollspy */
		const scrollSpy = new ScrollSpy(document.body, {
			target: '#nav',
			offset: 400,
		});
		const links = document.querySelectorAll('.list-group-item');

		var firstScrollSpyEl = document.querySelector('[data-bs-spy="scroll"]');
		firstScrollSpyEl.addEventListener('activate.bs.scrollspy', function () {
			links.forEach(function (el, index) {
				el.classList.remove('active');
			});
		});
	}

	if (menu) {
		menu.addEventListener('click', (e) => {
			e.preventDefault();
			showMenu('open');
		});
	}
	if (menuClose) {
		menuClose.addEventListener('click', (e) => {
			e.preventDefault();
			showMenu('close');
		});
	}
});
