import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import SplitType from 'split-type';
import marquee from 'vanilla-marquee'

gsap.registerPlugin(ScrollTrigger);

function wrapLine(lines) {
	lines.forEach((line, index) => {
		let origLine = line.innerHTML;
		let wrappedLine = '<div class="lineInner" data-order="' + index + '">' + origLine + '</div>';
		line.innerHTML = wrappedLine;
	});
}
function removeOverflow(target) {
	target.parentElement.classList.add('overflowNormal');
}

export default function animation() {
	gsap.registerPlugin(CustomEase);
	CustomEase.create('slideEase', 'M0,0,C0.92,0.012,0.108,1,1,1');

	const pageHome = document.getElementById('pageHome');
	if (pageHome) {

    new marquee( document.getElementById( 'brands' ), {
      pauseOnHover: true,
      duplicated:true,
      startVisible:true,
      gap:0,
      speed:100

    });

		
    /* paralax */
    const aboutImagesWrap = document.querySelectorAll('section.about .aboutText .picture__wrap');
    const aboutImages = document.querySelectorAll('section.about .aboutText picture');
    const imgTl = new gsap.timeline({
      scrollTrigger:{

        scrub: true,
        trigger: aboutImagesWrap
      }
    })
    imgTl.from(aboutImages[0],{y:-150}).from(aboutImages[1],{y:-50})
		
    /* anim top */
    
    const h1SPlit = new SplitType('section.top h1 .lineAnim', { types: 'lines, words' });
		wrapLine(h1SPlit.lines);
    const h1Lines = document.querySelectorAll('section.top h1 .lineInner');
    const topTl = new gsap.timeline();
    topTl.set('section.top',{autoAlpha:1})
          .from(h1Lines, { duration: 1, y: '100%', opacity: 0, stagger: 0.12, delay: 0.1 })
				  .call(function () {
					  h1Lines.forEach((element) => {
						  removeOverflow(element);
					  });
				  })
          .from(document.querySelector('section.top picture'),{autoAlpha:0, scale: 0.95, rotate: 5, duration:1},0.4)
          .from(document.querySelector('section.top picture img'),{scale: 1.15, duration:1},'-=1')
          .from(document.querySelector('section.top .wrap'),{autoAlpha:0,duration:0.8},'-=0.5')
          .from(document.querySelector('section.top .btn-primary'),{autoAlpha:0,duration:0.8},'-=0.3')
          .from(document.querySelector('section.top .scrollWrap'),{autoAlpha:0,duration:0.8},'-=0.2')
          

    gsap.to(document.querySelector('section.top picture'),
      { scrollTrigger: {
          trigger: document.querySelector('section.top '),
          scrub: true,
          start:'20%'
          
        },
        y:50,
      })
    /* anim about */
    const heading = document.querySelectorAll('section .heading__animation');

    heading.forEach(element => {
      const h2El = element.querySelector('h2');
      const headingSplit = new SplitType(h2El, { types: 'lines, words' });
      wrapLine(headingSplit.lines);
      const headingLines = h2El.querySelectorAll('.lineInner');
      const headingTl = new gsap.timeline({
        scrollTrigger:{
          trigger:element,
        }
      });

      headingTl.from(headingLines, { duration: 0.8, y: '100%', opacity: 0, stagger: 0.12, delay: 0.5 })
        .call(function () {
          headingLines.forEach((line) => {
            removeOverflow(line);
          });
        })
        .from(element.querySelector('p'),{autoAlpha:0,duration:0.8},'-=0.6')

     
    });
    


    
  
		/* marquue na scroll */
    const marquees = document.querySelectorAll('.marquee')
		const marqueeScroll = document.getElementById('marquee');
    const marqueeScrollBottom = document.getElementById('marqueeBottom');
		gsap.to(marqueeScroll, {
			scrollTrigger: {
				trigger: marqueeScroll,
				start: '-60% 100%',
				end: 'bottom top',
				scrub: true,
			},
			left: '-50vw',
			ease: 'none',
		});
    gsap.from(marqueeScrollBottom, {
			scrollTrigger: {
				trigger: marqueeScrollBottom,
				start: '-60% 100%',
				end: 'bottom top',
				scrub: true,
			},
			left: '-50vw',
			ease: 'none',
		});

		const marqueeSplit = new SplitType(marquees, { types: 'chars' });
		let marqueeChar = document.querySelectorAll('.marquee .char');

		let marqueeStar = document.querySelectorAll('.marquee svg');

		marqueeChar.forEach((element) => {
			element.addEventListener('mouseenter', (e) => {
				gsap.to(element, { color: 'rgba(0, 34, 98, 1)', duration: 0.4, overwrite: true });
			});
			element.addEventListener('mouseleave', (e) => {
				gsap.to(element, { color: 'rgba(0, 34, 98, 0)', duration: 3, delay: 1, overwrite: true });
			});
		});

		marqueeStar.forEach((element) => {
			element.addEventListener('mouseenter', (e) => {
				gsap.to(element.querySelector('.fill'), { fill: 'rgba(0, 34, 98, 1)', duration: 0.4, overwrite: true });
			});
			element.addEventListener('mouseleave', (e) => {
				gsap.to(element.querySelector('.fill'), { fill: 'rgba(0, 34, 98, 0)', duration: 3, delay: 1, overwrite: true });
			});
		});

		

		

		

		

		

		
	}
}
